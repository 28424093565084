/* eslint-disable import/export */
export * from '~/lib/utils/url_utility';

export const DOMAIN = 'jihulab.com';
export const FORUM_URL = 'https://forum.gitlab.cn';
// eslint-disable-next-line no-restricted-syntax
export const DOCS_URL = 'https://docs.gitlab.cn';

// About JiHu(GitLab) default host
// eslint-disable-next-line no-restricted-syntax
export const PROMO_HOST = 'about.gitlab.cn';

// About JiHu(GitLab) default url
export const PROMO_URL = `https://${PROMO_HOST}`;

// eslint-disable-next-line no-restricted-syntax
export const DOCS_URL_IN_EE_DIR = `${DOCS_URL}/jh`;
