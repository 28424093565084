import { memoize } from 'lodash';
import { loadScript } from 'jh/captcha/utils/load_script';

const TENCENT_CAPTCHA_LIB_URL_PREFIX = 'https://turing.captcha.qcloud.com/TCaptcha.js';

/**
 * See the Tencent captcha documentation for more details:
 *
 * https://cloud.tencent.com/document/product/1110/36841
 *
 */
export const initTencentCaptchaScript = memoize(() => {
  return loadScript(TENCENT_CAPTCHA_LIB_URL_PREFIX, (resolve) => {
    resolve(window.TencentCaptcha);
  });
});
