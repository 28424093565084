import * as Sentry from '@sentry/browser';

export function dispatchEvent(
  category = document.body.dataset.page,
  action = 'generic',
  data = {},
) {
  if (!category) {
    /* eslint-disable-next-line @gitlab/require-i18n-strings */
    throw new Error('Tracking: no category provided for tracking.');
  }

  let { value } = data;

  if (value !== undefined) {
    value = Number(value);
  }

  try {
    window.posthog.capture(action, {
      ...data,
      category,
    });
    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}
